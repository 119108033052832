<template>
    <div>
        <div v-if="authUserPermission['mail-configuration-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('mail_config') }}</h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="testConn(mailConfiguration.id)">
                                    <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>{{ tt('connection_test') }}</span>
                                </base-button>
                                <base-button size="sm" type="danger" @click="update(mailConfiguration.id, mailConfiguration)" v-if="authUserPermission['mail-configuration-update']">{{ tt('update') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <base-input :name="tt('driver')" :label="tt('driver')" :placeholder="tt('driver')" v-model="mailConfiguration.driver" rules="required"></base-input>

                        <base-input :name="tt('host')" :label="tt('host')" :placeholder="tt('host')" v-model="mailConfiguration.host" rules="required"></base-input>

                        <base-input :name="tt('port')" :label="tt('port')" :placeholder="tt('port')" v-model="mailConfiguration.port" rules="required"></base-input>

                        <base-input :name="tt('from_address')" :label="tt('from_address')" :placeholder="tt('from_address')" v-model="mailConfiguration.from_address" rules="required"></base-input>

                        <base-input :name="tt('from_name')" :label="tt('from_name')" :placeholder="tt('from_name')" v-model="mailConfiguration.from_name" rules="required"></base-input>

                        <base-input :name="tt('to')" :label="tt('to')" :placeholder="tt('to')" v-model="mailConfiguration.to" rules="required"></base-input>

                        <base-input :name="tt('encryption')" :label="tt('encryption')" :placeholder="tt('encryption')" v-model="mailConfiguration.encryption" rules="required"></base-input>

                        <base-input :name="tt('username')" :label="tt('username')" :placeholder="tt('username')" v-model="mailConfiguration.username" rules="required"></base-input>

                        <base-input :name="tt('password')" :label="tt('password')" :placeholder="tt('password')" v-model="mailConfiguration.password" rules="required"></base-input>

                        <base-input :name="tt('sendmail')" :label="tt('sendmail')" :placeholder="tt('sendmail')" v-model="mailConfiguration.sendmail" rules="required"></base-input>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import mailConfiguration from '@/services/setting/mailConfiguration.service';

    export default {        
        data() {
            return {                
                mailConfiguration: {},
                btnSave: {
                    onLoading: false
                },
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, mailConfiguration.get()).onSuccess(function(response) {    
                    context.mailConfiguration = response.data.data.data.data[0];
                }).call()
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            update(id, data) {
                let context = this;
                context.confirmDialog(this.tt('confirm_update')).then((result) => {
                    if (result.value) {
                        Api(context, mailConfiguration.update(id, data)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                        context.alertDialog('success', this.tt(success_update));
                    }
                })
            },
            testConn() {
                let context = this;
                this.btnSave.onLoading = true;

                Api(context, mailConfiguration.testConn()).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success'
                    });
                    context.get();
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                }).call();
            }
        }   
    };
</script>
<style></style>
